import React from 'react';
import './Category.scss';

type CategoryProps = {
    name: string;
    className?: string;
}

const Category: React.FunctionComponent<CategoryProps> = props => {
    return <section className={'category ' + props.className}>
        <h3><span>{props.name}</span></h3>
        {props.children}
    </section>;
}

export default Category;