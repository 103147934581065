import React from 'react';
import './Footer.scss';
import nyboligLogo from './Assets/nybolig_logo.svg';
import estateLogo from './Assets/estate_logo.svg';

const Footer = () => {
    const logo = process.env.REACT_APP_REALTOR === 'Nybolig' ? nyboligLogo : estateLogo;

    return <footer>
        <img className='logo' alt='logo' src={logo} />
    </footer>;
}

export default Footer;