import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { HashRouter } from 'react-router-dom';
import RouteChangeTracker from './routechangetracker';
import ReactGA from 'react-ga';
const TRACKING_ID = "UA-190453416-2"; // YOUR_OWN_TRACKING_ID
ReactGA.initialize(TRACKING_ID);


ReactDOM.render(
  <React.StrictMode>
    <HashRouter>
    <App />
    <RouteChangeTracker></RouteChangeTracker>
    </HashRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
