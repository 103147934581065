import React from 'react';
import './Option.scss';

type OptionProps = {
    number: string;
    name: string;
    description: string;
}

const Option: React.FunctionComponent<OptionProps> = props => {
    return <div className='option'>
        <div className='option-header'>
            <h4><span>{props.number}.</span>{props.name}</h4>
            <p>{props.description}</p>
        </div>
        <div className='option-content'>
            {props.children}
        </div>
    </div>;
}

export default Option;