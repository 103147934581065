import React, { useEffect, useRef, useState } from 'react';
import './Slider.scss';

type SliderProps = {
    beforeURL: string;
    afterURL: string;
}

const Slider: React.FunctionComponent<SliderProps> = props => {
    const [position, setPosition] = useState(0);
    const [dragging, setDragging] = useState(false);
    const ref = useRef<HTMLDivElement>(null);
    
    useEffect(() => {
        if(ref.current !== null){
            let node = ref.current;

            node.addEventListener("mousedown", onMouseDown);
            node.addEventListener("touchstart", onTouchStart);
            let bounds = ref.current.getBoundingClientRect();
            setPosition(bounds.width / 2);
            
            return () => {
                node.removeEventListener("mousedown", onMouseDown);
                node.removeEventListener("touchstart", onTouchStart);
            };
        }

    }, [ref.current]);

    useEffect(() => {
        if (dragging) {
          document.addEventListener("mouseup", onMouseUp);
          document.addEventListener("touchend", onMouseUp);
          document.addEventListener("mousemove", onMouseMove);
          document.addEventListener("touchmove", onTouchMove);
        } else {
          document.removeEventListener("mouseup", onMouseUp);
          document.removeEventListener("touchend", onMouseUp);
          document.removeEventListener("mousemove", onMouseMove);
          document.removeEventListener("touchmove", onTouchMove);
        }
        return () => {
          document.removeEventListener("mouseup", onMouseUp);
          document.removeEventListener("touchend", onMouseUp);
          document.removeEventListener("mousemove", onMouseMove);
          document.removeEventListener("touchmove", onTouchMove);
        };
      }, [dragging]);


      const onMouseMove = (e: MouseEvent) => {
        if (!dragging) return;
        if (!ref.current) return;

        let bounds = ref.current.getBoundingClientRect();
        let position = e.pageX;
        position -= bounds.x;
        position = Math.min(bounds.width, position);
        position = Math.max(0, position);
        setPosition(position);

        e.stopPropagation();
        e.preventDefault();
      }
      const onTouchMove = (e: TouchEvent) => {
        if (!dragging) return;
        if (!ref.current) return;

        let bounds = ref.current.getBoundingClientRect();
        let position = e.touches[0].pageX;
        position -= bounds.x;
        position = Math.min(bounds.width, position);
        position = Math.max(0, position);
        setPosition(position);

        e.stopPropagation();
      }
    
      function onMouseUp(e: MouseEvent | TouchEvent) {
        setDragging(false);
        e.stopPropagation();
        e.preventDefault();
      }
    
      function onMouseDown(e: MouseEvent) {
        if (e.button !== 0) return;
        if (!ref.current) return;

        setDragging(true);
    
        let bounds = ref.current.getBoundingClientRect();
        let position = e.pageX;
        position -= bounds.x;
        position = Math.min(bounds.width, position);
        position = Math.max(0, position);
        setPosition(position);
    
        e.stopPropagation();
        e.preventDefault();
      }
    
      function onTouchStart(e: TouchEvent) {
        if (!ref.current) return;

        setDragging(true);
    
        let bounds = ref.current.getBoundingClientRect();
        let position = e.touches[0].pageX;
        position -= bounds.x;
        position = Math.min(bounds.width, position);
        position = Math.max(0, position);
        setPosition(position);
    
        e.stopPropagation();
        e.preventDefault();
      }

    return <div ref={ref} className='slider'>
        <div className='after' style={{backgroundImage:`url("${props.afterURL}")`}} />
        <div className='before' style={{backgroundImage:`url("${props.beforeURL}")`, clip: `rect(0px, ${position}px, 5000px, 0px)`}} />
        <div className='handle' style={{left:`${position}px`}} />
    </div>;
}

export default Slider;