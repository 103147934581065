import React, { useState } from 'react';
import './Gallery.scss';
import Slider from './Slider';
import { NavLink } from 'react-router-dom';

type GalleryProps = {
    galleries: {
        title: string;
        items: {
            title: string;
            before: string;
            after: string;
        }[]
    }[];
}

const Gallery: React.FunctionComponent<GalleryProps> = props => {
    const [galleryIndex, setGalleryIndex] = useState(0);
    const [itemIndex, setItemIndex] = useState(0);
    
    return (
        <div className='gallery'>
            <NavLink to='/' className='close'></NavLink>

            <div className='gallery-container'>
                <div className='gallery-top'>
                    <div className='galleries'>
                        {props.galleries.map((e, i) => <div key={e.title} className={'dot ' + (i === galleryIndex ? 'current' : '')} onClick={() => { setGalleryIndex(i); setItemIndex(0); }}>{e.title}</div>)}
                    </div>
                    <div>
                        {props.galleries[galleryIndex].items.map((e, i) => <div key={e.title} className={'dot ' + (i === itemIndex ? 'current' : '')} onClick={() => setItemIndex(i)}>{e.title}</div>)}
                    </div>
                </div>
                <div className='player'>
                    <div className='player-left'>
                        <button disabled={itemIndex < 1} onClick={() => setItemIndex(itemIndex-1)}></button>
                    </div>
                    <div className='player-center'>
                        <div>
                            <Slider beforeURL={props.galleries[galleryIndex].items[itemIndex].before} afterURL={props.galleries[galleryIndex].items[itemIndex].after} />
                        </div>
                    </div>
                    <div className='player-right'>
                        <button disabled={itemIndex >= props.galleries[galleryIndex].items.length-1} onClick={() => setItemIndex(itemIndex+1)}></button>
                    </div>
                </div>
                <div className='gallery-bottom'>
                </div>
            </div>

        </div>);
}

export default Gallery;